import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ScannerComponent } from "./scanner/scanner.component";
import { RegistrationlistComponent } from "./registrationlist/registrationlist.component";
import { GuestDetailsComponent } from "./guest-details/guest-details.component";
import { WelcomeComponent } from "./welcome/welcome.component";
import { ValidUserGuard } from "valid-user-gaurd.service";

const routes: Routes = [
  {
    path: "",
    component: WelcomeComponent
  },
  {
    path: "scanner",
    component: ScannerComponent,
    canActivate: [ValidUserGuard]
  },
  {
    path: "guest",
    component: RegistrationlistComponent,
    canActivate: [ValidUserGuard]

  },
  {
    path: "details",
    component: GuestDetailsComponent
  }
  ,
  {
    path: "welcome",
    component: WelcomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
