import { Component, OnInit } from "@angular/core";
import { Guest } from "../guest.model";
import { GuestService } from "../guest.service";
import { map } from "rxjs/operators";
import { CheckinService } from "../service/checkin.service";
import { Router } from "@angular/router";
import { Checkin } from "../models/checkin.dto";


@Component({
  selector: "app-scanner",
  templateUrl: "./scanner.component.html",
  styleUrls: ["./scanner.component.scss"]
})
export class ScannerComponent implements OnInit {
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  qrResult: Guest;
  guestExist: boolean;
  sacnnerEnabled = true;
  guestInfo: any;
  validatedGuestInfo: any;
  totalGuests: number;
  isDropdownSelected: boolean = true;
  numberOfGuests: number;
  checkinErrorMessage: string = '';
  checkinSuccessMessage: string = '';
  isButtonClicked: boolean = false;
  isCheckinSuccess: boolean = false;
  selectGuestTotal: number;
  isGuestCheckedIn: boolean = false;
  isValidationSuccess: boolean = false;
  loading: boolean = true;

  constructor(private checkinService: CheckinService, private router: Router) { }

  ngOnInit() {

    const login = localStorage.getItem('sanmaLogin');
    if (login && login !== '') {

    } else {
      this.router.navigate(['/welcome']);
    }
  }

  clearResult(): void {
    this.qrResult = null;
  }

  onCodeResult(resultString: string): void {
    this.sacnnerEnabled = false;
    this.guestExist = null;
    if (this.checkQRJSON(resultString)) {
      this.guestExist = true;
      this.qrResult = JSON.parse(resultString);
      //  this.checkInGuest();
      // this.clearMessage();
    } else {
      this.guestExist = false;
      this.clearMessage();
    }
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
  }

  checkinGuest() {
    this.isButtonClicked = true;
    this.loading = true;
    const checkinDetails: Checkin = {
      email: this.guestInfo.email,
      fullname: this.guestInfo.name,
      guests: this.selectGuestTotal,
      phone: this.guestInfo.phone
    };

    this.checkinService.checkinGuest(checkinDetails).subscribe((res) => {
      if (res.statusCode == 200) {
        this.checkinSuccessMessage = "Guest checked in successfully";
        this.isDropdownSelected = true;
        this.isCheckinSuccess = true;
        this.loading = false;
      } else {
        this.isButtonClicked = false;
        this.isCheckinSuccess = false;
        this.loading = false;
        this.checkinErrorMessage = "An error occurred while checking in the guest. Please try again";
      }
    });
  }

  clearMessage() {
    setTimeout(() => {
      this.guestExist = null;
    }, 3000);
  }

  async checkQRJSON(qrString: string): Promise<boolean> {
    this.guestInfo = JSON.parse(qrString);
    console.log("guestinfo : " + JSON.stringify(this.guestInfo));
    await this.getGuestDetails();
    this.calculateTotalGuests();
    this.guestExist = true;
    this.validateGuest();
    return true;
  }

  calculateTotalGuests() {
    // Convert string values to numbers before summing up using Number()
    if(this.validatedGuestInfo) {
      this.totalGuests =
      Number(this.validatedGuestInfo.adults) +
      Number(this.validatedGuestInfo.kids_above_thirteen) +
      Number(this.validatedGuestInfo.kids_five_thirteen) +
      Number(this.validatedGuestInfo.parents);
    
    // Update other related variables
    this.numberOfGuests = this.totalGuests;
    this.selectGuestTotal = this.totalGuests;
    this.guestInfo.adults = this.validatedGuestInfo.adults;
    this.guestInfo.parents = this.validatedGuestInfo.parents;
    this.guestInfo.kidsabovethirteen =this.validatedGuestInfo.kids_above_thirteen;
    this.guestInfo.kidsbetweenfiveandtwelve = this.validatedGuestInfo.kids_five_thirteen;
    } else {
    this.totalGuests =
      Number(this.guestInfo.adults) +
      Number(this.guestInfo.kidsabovethirteen) +
      Number(this.guestInfo.kidsbetweenfiveandtwelve) +
      Number(this.guestInfo.parents);
    
    // Update other related variables
    this.numberOfGuests = this.totalGuests;
    this.selectGuestTotal = this.totalGuests;
   
    }
  }
  async getGuestDetails(): Promise<void> {
    const checkinDetails: Checkin = {
      email: this.guestInfo.email,
      fullname: this.guestInfo.name,
      guests: this.selectGuestTotal,
      phone: this.guestInfo.phone
    };
  
    try {
      // Wait for the result of the validation using toPromise()
      const res = await this.checkinService.validateGuest(checkinDetails).toPromise();
  
      if (res.statusCode === 200) {
        console.log("******* 200: " + JSON.stringify(res));
        this.isValidationSuccess = true;
        if (res.body.data.length > 0) {
          this.validatedGuestInfo = res.body.data[0];
          console.log("******* 200: Got the response");
        }
      }
    } catch (error) {
      console.error("Error during guest validation:", error);
    }
  }


  validateGuest() {
    const checkinDetails: Checkin = {
      email: this.guestInfo.email,
      fullname: this.guestInfo.name,
      guests: this.selectGuestTotal,
      phone: this.guestInfo.phone
    };
    this.checkinService.validateGuest(checkinDetails).subscribe((res) => {
      if (res.statusCode == 200) {
        console.log("******* 200" + JSON.stringify(res));
        this.isValidationSuccess = true;
        if (res.body.data.length > 0 && res.body.data[0].checkedin === true) {
          this.checkinSuccessMessage = "Already checked in";
          this.guestInfo.checkinnumberofguest = res.body.data[0].checkinnumberofguest;
          this.isGuestCheckedIn = true;
          console.log("******* 200 and is checked in");
        } else if (res.body.data.length < 0) {
          this.isGuestCheckedIn = false;
          this.checkinErrorMessage = "Scanned Guest is not in the list. Please register the guest first";
        }
      } else {
        this.isButtonClicked = false;
        this.isValidationSuccess = false;
        this.checkinErrorMessage = "An error occurred while checking in the guest. Please try again";
      }
    });


  }

  scanAnotherOne() {
    this.sacnnerEnabled = true;
    this.guestExist = false;
    this.qrResult = null;
    this.checkinErrorMessage = '';
    this.checkinSuccessMessage = '';
    this.isButtonClicked = false;
    this.isCheckinSuccess = false;
    this.selectGuestTotal = 0;
    this.isGuestCheckedIn = false;
    this.isValidationSuccess = false;
    this.guestInfo = null;
  }

  generateGuestNumbers(): number[] {
    return Array.from({ length: this.totalGuests }, (_, index) => index + 1);
  }

  onDropdownChange() {
    this.isDropdownSelected = this.numberOfGuests !== undefined && this.numberOfGuests !== null;
  }

  onRadioChange(option: number) {
    this.isDropdownSelected = this.numberOfGuests !== undefined && this.numberOfGuests !== null;
    console.log(option);
    this.selectGuestTotal = option;
  }

  handleCloseImageClick() {
    this.router.navigate(['/guest']);
  }


}
