import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Checkin } from '../models/checkin.dto';

@Injectable({
  providedIn: 'root',
})
export class CheckinService {
  constructor(private http: HttpClient) {}

  checkinGuest(checkinDetails: Checkin) {
  
    return this.http.post<any>('https://5kuhj9j9wk.execute-api.us-east-1.amazonaws.com/Dev', checkinDetails);
  }

  validateGuest(checkinDetails: Checkin) {
    return this.http.post<any>('https://f2ti1xowm2.execute-api.us-east-1.amazonaws.com/Dev', checkinDetails);
  }
}
