import { Component, OnInit } from '@angular/core';
import { RegisteredGuestService } from '../service/registration.service';
import { RegisteredGuestDetailsService } from '../service/registeredguest.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrationlist',
  templateUrl: './registrationlist.component.html',
  styleUrls: ['./registrationlist.component.scss']
})
export class RegistrationlistComponent implements OnInit {
  results: any[] | undefined = [];
  filteredResults = this.results.slice(); 
  nameFilter = '';
  emailFilter = '';
  phoneFilter = '';
  total_registerd_guests: number = 0;
  total_checkedin_guests: number = 0;
  loading: boolean = true;

  constructor(private registeredGuestService: RegisteredGuestService,
    private registerdGuestDetailsService: RegisteredGuestDetailsService,
    private router: Router) { }

  ngOnInit() {
    this.registeredGuestService.getRegisteredGuests().subscribe(data => {
      if(data.statusCode && data.statusCode == 401) {
        localStorage.removeItem('sanmaLogin');
        this.router.navigate(['/welcome']);
      } else {
      this.loading = false;
      this.results = data.body.data;
      this.filteredResults = this.results.slice();
      this.calcluateTotalGuests(this.filteredResults);
      }
    });
  }


  calcluateTotalGuests(results: any[]) {
    results.forEach((element: any) => {
      this.total_registerd_guests += element.total_guests || 0;
      if (element.checkinnumberofguest && parseInt(element.checkinnumberofguest, 10) > 0) {
        this.total_checkedin_guests += parseInt(element.checkinnumberofguest, 10) || 0;
      }

    });

  }
  showDetails(guest: any) {
    this.registerdGuestDetailsService.setSelectedGuest(guest);
  }

  applyFilters() {
    this.filteredResults = this.results.filter((result) =>
      result['fullname'].toLowerCase().includes(this.nameFilter.toLowerCase()) &&
      result['emailaddress'].toLowerCase().includes(this.emailFilter.toLowerCase()) &&
      result['mobilenumber'].toLowerCase().includes(this.phoneFilter.toLowerCase())
    );
  }

  getColor(result: any, even: boolean): string {
    if (result['checkedin']) {
      return 'rgb(231 230 216)';
    } else {
      return even ? '#ffffff' : '#f9f9f9';
    }
  }

  navigateToDetails(guest: any) {
    console.log(guest);
    this.registerdGuestDetailsService.setSelectedGuest(guest);
    this.router.navigate(['/details']);
  }

}
