import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegisteredGuestDetailsService {
  selectedGuest: any;

  setSelectedGuest(guest: any) {
    this.selectedGuest = guest;
  }

  getSelectedGuest() {
    return this.selectedGuest;
  }
}