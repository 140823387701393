import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Login } from '../models/login.dto';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class LogininService {
  constructor(private http: HttpClient) {}

  loginUser(loginDetails: Login) {
  
    const username = loginDetails.username;
    const password = loginDetails.password;
    const hashedPassword = this.hashPassword(password);
    const hashedLoginDetails: Login ={
        username: username,
        password: hashedPassword
    }
    return this.http.post<any>('https://2tx8qsflil.execute-api.us-east-1.amazonaws.com/Dev', hashedLoginDetails);

  }

  // Function to hash a password on the client side
hashPassword(password: string): string {
    const hashedPassword = CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    return hashedPassword;
  }
}
