import { Component, OnInit } from '@angular/core';
import { Guest } from "../guest.model";
import { GuestService } from "../guest.service";
import { map } from "rxjs/operators";
import { RegisteredGuestDetailsService } from '../service/registeredguest.service';
import { CheckinService } from '../service/checkin.service';
import { Checkin } from '../models/checkin.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-guest-details',
  templateUrl: './guest-details.component.html',
  styleUrls: ['./guest-details.component.scss']
})
export class GuestDetailsComponent implements OnInit  {
availableDevices: MediaDeviceInfo[];
currentDevice: MediaDeviceInfo = null;
hasDevices: boolean;
hasPermission: boolean;
qrResult: Guest;
guestExist: boolean;
sacnnerEnabled = true;
guestInfo: any;
totalGuests: number;
isDropdownSelected: boolean = true;
numberOfGuests: number;
selectedGuest: any;
checkinErrorMessage: string = '';
checkinSuccessMessage: string = '';
isButtonClicked: boolean = false;
isCheckinSuccess: boolean = false;
selectGuestTotal: number
loading: boolean = true;

constructor(private guestService: GuestService,
  private registerdGuestDetailsService: RegisteredGuestDetailsService,
  private checkinService: CheckinService,private router: Router) {}

  ngOnInit() {
    this.guestInfo = this.registerdGuestDetailsService.getSelectedGuest();
    if(!this.guestInfo) {
      this.router.navigate(['/guest']);
    } else {
    this.calculateTotalGuests();
    }
  }

clearResult(): void {
  this.qrResult = null;
}

onHasPermission(has: boolean): void {
  this.hasPermission = has;
}

clearMessage() {
  setTimeout(() => {
    this.guestExist = null;
  }, 3000);
}

calculateTotalGuests() {
  // Convert string values to numeric before summing up
  this.totalGuests =
    +this.guestInfo.adults +
    +this.guestInfo.kids_above_thirteen +
    +this.guestInfo.kids_five_thirteen +
    +this.guestInfo.parents;
    this.numberOfGuests = this.totalGuests;
    this.selectGuestTotal = this.totalGuests;
    console.log(this.selectGuestTotal);
}

checkinGuest() {
this.isButtonClicked = true;
const checkinDetails: Checkin = {
  email: this.guestInfo.emailaddress,
  fullname: this.guestInfo.fullname,
  guests: this.selectGuestTotal,
  phone: this.guestInfo.mobilenumber
};

  this.checkinService.checkinGuest(checkinDetails).subscribe((res) => {
    if(res.statusCode == 200) {
      this.checkinSuccessMessage = "Guest checked in successfully";
      this.isDropdownSelected = true;
      this.isCheckinSuccess = true;
      this.loading = false;
    } else {
      this.isButtonClicked = false;
      this.isCheckinSuccess = false;
      this.loading = false;
      this.checkinErrorMessage = "An error occurred while checking in the guest. Please try again";
    }
  });
}

handleCloseImageClick () {
  this.router.navigate(['/guest']);
}

ScanAnotherOne() {
  this.sacnnerEnabled = true;
  this.guestExist = false;
}

generateGuestNumbers(): number[] {
  return Array.from({ length: this.totalGuests }, (_, index) => index + 1);
}

onDropdownChange() {
  this.isDropdownSelected = this.numberOfGuests !== undefined && this.numberOfGuests !== null;
}

onRadioChange(option: number) {
  this.isDropdownSelected = this.numberOfGuests !== undefined && this.numberOfGuests !== null;
  console.log(option);
  this.selectGuestTotal = option;
}

}
