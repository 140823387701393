import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavbarComponent implements OnInit {
  public isLoggedin: boolean = false;
  constructor(private cdr: ChangeDetectorRef, private router: Router) { }

  ngOnInit() {

    const login = localStorage.getItem('sanmaLogin');
    if(login && login !== '') {
      this.isLoggedin = true;
    }
  }

  logout() {
    localStorage.removeItem('sanmaLogin');
    this.isLoggedin = false;
    this.router.navigate(['/welcome']);
  }
  
}

