import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegisteredGuestService {
  constructor(private http: HttpClient) {}

  getRegisteredGuests() {
    const authToken = localStorage.getItem('sanmaLogin');
    const requestBody = {
        "authToken": authToken
    }
    return this.http.post<any>('https://7vrv1x8pwl.execute-api.us-east-1.amazonaws.com/Dev', requestBody);
  }
}
