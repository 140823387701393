import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LogininService } from '../service/login.service';
import { Login } from '../models/login.dto';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  logininErrorMessage: string = '';

  ngOnInit() {

    const login = localStorage.getItem('sanmaLogin');
    if(login && login !== '') {
      this.router.navigate(['/guest']);
    } else {
      this.router.navigate(['/welcome']);
    }
  }
  registrationForm: FormGroup; // Declare the form group property

  constructor(private loginService: LogininService,
    private router: Router) {
    // Initialize the form group in the constructor
    this.registrationForm = new FormGroup({
      loginName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      // Add more form controls as needed
    });
  }

  onSubmit() {
    // Your existing form submission logic
  
    // Get the values from the form controls
    const loginNameValue = this.registrationForm.get('loginName').value;
    const passwordValue = this.registrationForm.get('password').value;
    const loginDetails: Login = {
      username: loginNameValue,
      password: passwordValue
    }
  
    this.loginService.loginUser(loginDetails).subscribe((res) => {
      if(res.statusCode == 200) {
        const response =JSON.parse(res.body);
        localStorage.setItem('sanmaLogin', response.authToken);
        this.router.navigate(['/guest']);
      } else {
        this.logininErrorMessage = "Invalid Login Details"
        localStorage.removeItem('sanmaLogin');
      }
    });

  }



}