import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LogininService } from 'src/app/service/login.service';

@Injectable({
  providedIn: 'root'
})
export class ValidUserGuard implements CanActivate {
  public validIds: any = [];
  constructor(private router: Router, private loginService: LogininService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const loggedInStatus = localStorage.getItem('sanmaLogin');
    if (loggedInStatus && loggedInStatus.length > 0) {
            return of(true);
          } else {
            // If not a valid user, navigate to the welcome page
            this.router.navigate(['/welcome'], { state: { returnUrl: state.url } }); // Set state here
            return of(false); // Indicate that navigation should not proceed
          }
   }

}
